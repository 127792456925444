/* Global styles, do not import this file */

@import 'variables.scss';
@import 'material-styling.scss';

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Noto', Arial, sans-serif;
  overflow-x: hidden;
}

.tooltip-linebreak {
  white-space: pre-line;
}

.pending-with-error {
  background-color: rgba(247, 52, 52, 0.623);
}

.heading {
  font-weight: bold;
  font-size: 1.2em;
}

.mat-form-field-underline.mat-disabled {
  display: none !important;
}

ul,
ol {
  margin: 0;
  padding-left: 20px;
}

.toolbar .mat-button {
  min-width: $toolbar-button-width;
  padding: $toolbar-button-padding;
}

.mat-option,
.mat-tab-label {
  user-select: none;
}

.mat-tooltip {
  word-wrap: break-word;
  font-size: 14px !important;
}

@media print {
  cars-header,
  cars-chat,
  cars-canvas {
    display: none !important;
  }

  button {
    display: none !important;
  }

  cars-container .container {
    margin-top: 0px !important;
  }

  html,
  body,
  .pad-container {
    height: initial !important;
    overflow: initial !important;
  }

  .pad {
    margin: initial !important;
    border: initial !important;
  }

  /* Fixes printing on firefox */
  .pad,
  .content,
  cars-clause-list {
    overflow: visible !important;
    display: block !important;
  }

  .main-body {
    top: 0 !important;
  }
}

.canvas-selection {
  z-index: 0;
  opacity: 0.6;
  pointer-events: none;
  background-color: #d90000;
}

.canvas-suggestion {
  z-index: 0;
  opacity: 0.6;
  pointer-events: none;
  background-color: $pantone-326;
}

.canvas-search-result {
  z-index: 0;
  opacity: 0.6;
  pointer-events: none;
  background-color: yellow;
}

.canvas-selected-search-result {
  z-index: 0;
  opacity: 0.6;
  pointer-events: none;
  background-color: orange;
}

.canvas-pending-suggestion {
  z-index: 0;
  opacity: 0.6;
  pointer-events: none;
  background-color: #90caf9;
}

.canvas-spelling-error {
  background: url('/assets/underline.gif') bottom repeat-x;
  cursor: text;
  z-index: 10;
}

.canvas-changelog-range {
  mix-blend-mode: multiply; // Don't dim the text underneath
  z-index: 11;
  padding-bottom: 2px;
  padding-top: 3px;
  opacity: 0.6;
}

.canvas-hovered-changelog-range {
  opacity: 0.8;
}

.canvas-deleted-changelog-range {
  background: url('/assets/strikethrough.gif') center repeat-x;
  background-color: white;
  z-index: 11;
  padding-bottom: 1px;
  opacity: 0.3;
}

.version-moved {
  color: navy;
  background-color: rgb(220, 220, 255);
  border-color: blue !important;
  .group-title,
  .index {
    background-color: rgb(220, 220, 255) !important;
    color: navy !important;
  }
}

.version-created {
  color: green;
  text-decoration: underline;
  border-color: green !important;
  background-color: rgb(220, 255, 220);
  .group-title,
  .index {
    background-color: rgb(220, 255, 220) !important;
    color: green !important;
  }
}

.version-removed {
  color: #b71c1c;
  border-color: #b71c1c !important;
  text-decoration: line-through;
  background-color: rgb(255, 220, 220) !important;
  .group-title,
  .index {
    background-color: rgb(255, 220, 220) !important;
    color: #b71c1c !important;
  }
}

.version-moved,
.version-created,
.version-removed {
  .margin-icons-container {
    color: initial;
  }
}

.custom-modalbox > mat-dialog-container {
  padding: 0;
  background-color: rgb(230, 230, 230);
}

.list-hovertip-menu,
.move-delete-hovertip-menu {
  min-height: unset !important;
  min-width: unset !important;
  .mat-menu-content:not(:empty) {
    padding: 4px 0;
  }
}

.deprecated-sfr,
.deprecated-si {
  color: #b71c1c;
  text-decoration: line-through;

  .group-title {
    color: #b71c1c !important;
  }

  .index {
    text-decoration: line-through;
  }

  cars-readonly-fragment {
    span {
      color: #b71c1c;
    }
  }
}

cars-version-review-status-toggle {
  mat-icon {
    color: white !important;
  }

  mat-progress-spinner circle {
    stroke: white !important;
  }
}

.default-focus-style {
  :not([disableDefaultFocus], mat-select, [mat-icon-button]):focus {
    border: 3px solid black !important;
  }
  .mat-select:focus {
    outline: 3px solid black !important;
    outline-offset: 2px;
  }

  [mat-icon-button]:focus {
    outline: 3px solid black !important;
    outline-offset: 2px;
  }
  .cdk-keyboard-focused.mat-checkbox {
    border: 3px solid black !important;
  }
}

.mat-menu-item:focus {
  border-right: navy 5px solid;
}

[enableDefaultFocus]:focus {
  border: 3px solid black !important;
}
