/*
  This file contains styles for overriding material defaults.
  We need these to account for the deprecation of the ::ng-deep selector.
  These elements are only present in the stated components.
  See https://material.angular.io/guide/customizing-component-styles
*/
@import 'variables.scss';

/* navigation component */
cars-navigation {
  mat-nav-list .mat-list-item,
  mat-nav-list .mat-list-item-content {
    height: initial !important;
    padding: 0px !important;
  }
}

/* container component */
cars-container {
  .mat-tab-label,
  .mat-tab-label-active {
    min-width: 0 !important;
    width: 50% !important;
    padding: 3px !important;
    height: 35px !important;
  }

  .mat-tab-label-active {
    opacity: 0.9 !important;
  }

  .mat-tab-body-wrapper {
    height: 100%;
  }
}

/* user autocomplete */
cars-user-autocomplete {
  .user-information mat-list mat-list-item .mat-list-item-content {
    height: 30px !important;
  }
}

/* user list autocomplete */
cars-user-list-autocomplete {
  .user-list-autocomplete mat-list mat-list-item .mat-list-item-content {
    height: 20px !important;
  }
}

/* document creator, documents, document versions */
mat-spinner {
  & svg {
    height: 30px !important;
    width: 30px !important;
  }
}

.overlay-content mat-spinner {
  & svg {
    height: auto !important;
    width: auto !important;
  }
}

/* section */
cars-section-list-item {
  .section {
    mat-form-field {
      .mat-form-field-wrapper {
        padding-bottom: 0px;
      }

      .mat-form-field-infix {
        border-top: initial;
      }

      .mat-form-field-underline {
        bottom: 0.2em;
      }

      .mat-form-field-infix {
        width: inherit;
      }

      &.mat-form-field-disabled {
        .mat-form-field-underline {
          display: none;
        }
      }
    }
  }
}

/* section group list item */
cars-section-group-list-item {
  .section-group {
    mat-form-field {
      .mat-form-field-wrapper {
        padding-bottom: 0px;
      }

      .mat-form-field-infix {
        border-top: initial;
      }

      .mat-form-field-underline {
        bottom: 0.2em;
      }

      .mat-form-field-infix {
        width: inherit;
      }

      &.mat-form-field-disabled {
        .mat-form-field-underline {
          display: none;
        }
      }
    }
  }
}

/* chat */
cars-chat {
  .create-message {
    mat-form-field {
      height: 100%;
      width: 100% !important;

      .mat-form-field-wrapper {
        height: calc(100% - 30px);

        .mat-form-field-flex,
        .mat-form-field-infix {
          height: 100%;
        }
      }
    }
  }
}

/* type buttons */
cars-clause-type-selector {
  .mat-form-field-infix.mat-form-field-infix {
    border-top: 0;
  }

  .mat-form-field-wrapper.mat-form-field-wrapper {
    margin-bottom: -1.25em;
  }

  .mat-form-field-label.mat-form-field-label,
  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: rgba(0, 0, 0, 0.54);
  }

  .mat-form-field-ripple.mat-form-field-ripple {
    display: none;
  }
}

/* Changelog toolbar */
cars-changelog {
  cars-toolbar.published {
    & > .toolbar:first-child {
      width: 100%;
    }

    .toolbar-container {
      display: flex;
    }

    mat-form-field {
      flex: 1;
      margin: 0 0.5em;
    }

    .mat-form-field-infix.mat-form-field-infix {
      border-top: 0;
    }

    .mat-form-field-wrapper.mat-form-field-wrapper {
      margin-bottom: -1.25em;
    }

    .alt-small {
      margin-top: -24px;
      margin-left: 10px;
      z-index: 1;
    }
  }
}

/* Changelog link table */
cars-link-table {
  cars-excerpt {
    cars-clause,
    cars-no-op-clause-fragment {
      .clause {
        line-height: 14px !important;
        font-size: 0.7em !important;
        padding: 0 !important;

        table {
          font-size: 1em !important;
        }

        cars-figure-fragment {
          .title {
            font-size: 1em !important;
          }
        }

        cars-equation-fragment {
          caption {
            font-size: 1em !important;
          }

          .render {
            font-size: 1em !important;
          }
        }
      }

      .content {
        padding: 5px !important;
      }
    }

    cars-clause-group-fragment {
      & > .boundary-container.top,
      & > .group-heading,
      & > .boundary-container.bottom {
        font-size: 0.7em;
      }

      & > .group-heading {
        height: 1em !important;
      }
    }
  }
}

/* discussion */
cars-discussions mat-tab-group {
  flex: 1;
  overflow: hidden;
}

/* clause links sidebar */
cars-clause-link-selector {
  .mat-checkbox-layout {
    white-space: normal !important; // Wraps text within the checkbox label
  }

  .mat-checkbox-inner-container {
    margin-top: 0px; // Aligns the checkbox to the top text line of the cell
  }
}

cars-document-linkage {
  .mat-checkbox-layout {
    white-space: normal !important;
  }
}

/* section comments */
cars-section-comments {
  .textarea-container {
    & mat-form-field {
      width: 98%;
      height: calc(100% - 40px);
      padding: 5px;

      & .mat-form-field-wrapper {
        height: 100%;

        & .mat-form-field-label {
          padding-top: 5px;
        }

        & .mat-form-field-flex {
          background-color: white;
          height: 100%;

          & .mat-form-field-infix {
            height: inherit;
          }
        }
      }
    }
  }
}

/* offline version status */
cars-offline-version-status {
  mat-spinner {
    & svg {
      height: 20px !important;
      width: 20px !important;
    }
  }
}

cars-section-background {
  & cars-clause {
    & .content {
      padding: 0 50px !important;
    }
    & .index {
      padding-top: 0 !important;
    }
  }
}

cars-clause-change-summary {
  & cars-clause {
    padding-bottom: 0 !important;
    & .content {
      padding: 0 !important;
    }
  }
}

cars-changelog {
  cars-clause,
  cars-no-op-clause-fragment {
    .content {
      cursor: text !important;
    }
    .index {
      cursor: text !important;
    }
  }
}

cars-documents {
  mat-tab-group,
  mat-tab-body,
  .mat-tab-body-wrapper {
    height: 100%;
  }
}

cars-version-settings {
  mat-checkbox .mat-checkbox-label {
    white-space: normal !important;
  }
  mat-checkbox .mat-checkbox-inner-container {
    margin-top: 0;
    margin-bottom: auto;
    .mat-checkbox-frame {
      border-color: white;
    }
  }
}

cars-version-publication-options {
  mat-form-field {
    .mat-form-field-label {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    .mat-form-field-underline {
      background-color: rgba(255, 255, 255, 0.7) !important;
    }
  }
}

cars-version-creator {
  mat-checkbox .mat-checkbox-label {
    white-space: normal !important;
  }
  mat-checkbox .mat-checkbox-inner-container {
    margin-top: 0;
    margin-bottom: auto;
  }
}

cars-section-clone {
  mat-checkbox .mat-checkbox-label {
    white-space: normal !important;
  }
  mat-checkbox .mat-checkbox-inner-container {
    margin-top: 0;
    margin-bottom: auto;
  }
}

cars-document-versions {
  mat-checkbox {
    margin-left: 1em;
  }

  mat-header-cell .mat-sort-header-button {
    text-align: left;
  }
}

// Triggered from the header component
.mat-menu-panel.notification-area {
  min-width: 380px;
  max-width: 25vw;
}

cars-notification {
  mat-list-item {
    min-height: 72px;
    height: 100% !important;
    .mat-line {
      white-space: normal !important;
      text-overflow: initial !important;
    }
    .mat-list-item-content {
      min-height: 72px;
    }
  }
}

cars-manage-references {
  .mat-list-item-content {
    display: block !important;
  }
  .mat-nav-list {
    display: block !important;
  }
  .mat-list-item {
    display: block !important;
  }
}

.mat-calendar-period-button {
  pointer-events: none !important;
}

.mat-calendar-arrow {
  display: none !important;
}

cars-version-aggregates-list {
  mat-card.version-card {
    border-radius: 10px !important;
    box-shadow: none !important;
    margin-bottom: 5px !important;
    border-left: 1px solid rgba(#000000, 0.2);
    border: 1px solid rgba(#000000, 0.2);
    padding: 10px;
    &:hover,
    &:focus-visible {
      outline: none;
      border-left: 1px solid rgba(#008bcb, 1);
      border: 2px solid rgba(#008bcb, 1);
    }
  }
}

cars-dashboard-sidebar {
  mat-card.document-card {
    box-shadow: none;
    border-radius: 0px;
    border-left: 4px solid rgba(#ffffff, 1);
    &:hover {
      border-left: 4px solid rgba(#008bcb, 1);
    }
  }
}

cars-dashboard-table {
  mat-cell.no-padding {
    padding: 0px !important;
  }

  mat-row.expandable-row {
    padding: 0px !important;
  }

  mat-row.expanded {
    border-color: rgba($color: #000000, $alpha: 0.2) !important;
  }
}

cars-section-table {
  mat-table {
    cursor: default;
    border: 0px !important;
    background-color: rgba(#000000, 0);
  }
  mat-row {
    border: 0px !important;
    border-radius: 0px !important;
  }
}

cars-specifier-instruction-list-options {
  mat-radio-button {
    .mat-radio-label {
      align-items: flex-start;
    }
  }
}

cars-section-references {
  .mat-checkbox-layout {
    white-space: normal !important;
  }
}
