// These are the actual colors defined in the highways visual identity guide:
// http://assets.highways.gov.uk/specialist-information/guidance-and-best-practice-ha-visual-identity/Highways%20Agency's%20visual%20identity%20specifications%20(v02-13).pdf

// Primary colour palette
$pantone-284: rgb(108, 174, 223); // Used for all corporate, general and internal literature.
$pantone-287: rgb(0, 83, 155); // Used for all literature specifically relating to motorways.
$pantone-349: rgb(0, 103, 55); // Used for all literature specifically relating to all-purpose trunk roads
$pantone-282: rgb(0, 46, 95);

// The Visual Identity Specifications define process black as 0,0,100, which is assumed to be a mistake.
// $process-black: rgb(0, 0, 100); // Used for body copy.
$process-black: rgb(0, 0, 0); // Used for body copy.

// Secondary colour palette
$pantone-2622: rgb(84, 7, 91);
$pantone-1797: rgb(227, 27, 35);
$pantone-354: rgb(0, 178, 90);
$pantone-4985: rgb(147, 79, 70);
$pantone-240: rgb(201, 48, 146);
$pantone-873: rgb(168, 153, 110);
$pantone-368: rgb(123, 193, 67);
$pantone-4715: rgb(176, 118, 96);
$pantone-2573: rgb(169, 123, 182);
$pantone-021: rgb(247, 143, 30);
$pantone-326: rgb(0, 177, 176);
$pantone-5487: rgb(89, 123, 123);
$pantone-5285: rgb(145, 148, 182);
$pantone-123: rgb(255, 196, 37);
$pantone-299: rgb(0, 157, 220);
$pantone-5507: rgb(174, 187, 186);
