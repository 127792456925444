/* Variables definitions, for importing */

@import 'visual-identity-colours.scss';

$he-blue: $pantone-287;
$he-green: $pantone-326;
$he-grey: $pantone-5507;
$he-red: $pantone-1797;
$he-orange: rgb(169, 86, 9);
$table-highlight-color: #eee;
$table-selected-row: rgba(161, 192, 218, 1);

$dark-grey: #707070;

$readonly-text-colour: #575757; // Minimum contrast for WCAG AAA normal text

$reordering-selected-colour: rgb(229, 229, 229);

$header-line-height: 40px;
$header-padding: 8px;

$header-height: calc(#{$header-line-height} + (2 *#{$header-padding}));

$toolbar-button-padding: 0 1em;
$toolbar-button-width: 30px;
$toolbar-height: 36px;
$toolbar-border-style: 1px solid #ccc;
$toolbar-background-color: #eee;

$button-size: 50px;

$vertical-toolbar-width: 56px;

@mixin vertical-toolbar() {
  background-color: $toolbar-background-color;
  width: $vertical-toolbar-width;
  border-right: $toolbar-border-style;
  button {
    height: $toolbar-height;
  }
}

@mixin dropdown-header() {
  width: 98%;
  height: $toolbar-height;
  display: flex;
  align-items: center;

  .dropdown-container {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0 0 0 1em;

    .dropdown-label {
      margin-right: 1em;
      white-space: nowrap;
    }

    mat-form-field {
      flex: 1;
    }
  }

  a {
    display: flex;
    align-items: center;
    margin: 0 1em 0 1em;
    height: calc(100% - 10px);
    width: 5.5em;
    justify-content: center;
  }

  mat-progress-bar {
    position: absolute;
    top: $toolbar-height;
  }
}
